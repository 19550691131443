import React from "react";
import { Container } from "react-bootstrap";

import Icon from "../elements/Icon";

const Footer = ({ settings }) => {
  const { logo_white: logo, footer_copyright: copyright } = settings.data;

  return (
    <footer
      className="site-footer d-flex align-items-center justify-content-center text-white bg-gradient-brand clip clip--top"
      style={{ zIndex: "-1" }}
    >
      <Container className="pt-8 pt-md-10 pb-8 px-5 px-md-2 text-center">
        <Icon icon={logo} />
        <p className="site-footer__copyright mt-5">
          {copyright.replace("[year]", new Date().getFullYear())}
          <br />
          <a
            href="https://think-plus.gr"
            target="_blank"
            rel="noopener noreferrer"
            title="Think Plus | creative & software agency"
            className="text-white text-decoration-none"
          >
            Designed &amp; Developed by Think Plus
          </a>
        </p>
      </Container>
    </footer>
  );
};

export default Footer;
