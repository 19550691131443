import * as React from "react";
import { Dropdown } from "react-bootstrap";

import { linkResolver } from "../../utilities/prismic-linkResolver";

const LanguageSwitcher = ({ activeDocMeta }) => {
  if (!activeDocMeta) return null;

  // Hide switcher when we don't have other languages
  if (activeDocMeta.alternateLanguages?.length <= 0) return null;

  const currentLang = activeDocMeta.lang;
  const currentLangOption = currentLang.slice(0, 2).toUpperCase();

  const alternateLangOptions = activeDocMeta.alternateLanguages.map(
    (altLang) => (
      <Dropdown.Item
        href={linkResolver(altLang)}
        key={`alt-lang-${altLang}`}
        className="py-2"
      >
        {altLang.lang.slice(0, 2).toUpperCase()}
      </Dropdown.Item>
    )
  );

  return (
    <Dropdown className="site-language-switcher">
      <Dropdown.Toggle variant="light" id="lang.dropdown">
        <span className="pe-2">{currentLangOption}</span>
      </Dropdown.Toggle>
      <Dropdown.Menu align="end" className="text-center" renderOnMount>
        {alternateLangOptions}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default LanguageSwitcher;
