import React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";

const Anchor = (props) => {
  const {
    children,
    href,
    target,
    title,
    className,
    "aria-label": ariaLabel,
    rel,
  } = props;

  const { siteUrl } = useStaticQuery(graphql`
    query anchorQuery {
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `).site.siteMetadata;

  // Return a span element when href is not provided
  if (!href) {
    return (
      <span
        title={title}
        className={`anchor-empty ${className || ""}`}
        aria-label={ariaLabel}
      >
        {children}
      </span>
    );
  }

  // On external, tel and mailto links use a simple anchor element
  if (
    (href.match(/(^\s?http(s?))|(^\s?tel:)|(^\s?mailto:)/gi) &&
      !href.includes(siteUrl)) ||
    href.includes(`${siteUrl}/app`)
  ) {
    return (
      <a
        href={
          href.includes(`${siteUrl}/app`) ? href.replace(siteUrl, "") : href
        }
        target={target}
        title={title}
        className={`anchor-link ${className || ""}`}
        aria-label={ariaLabel}
        rel={rel}
      >
        {children}
      </a>
    );
  }

  // On internal links use gatsby link component
  return (
    <Link
      to={href.includes(siteUrl) ? href.replace(siteUrl, "") : href}
      target={target}
      title={title}
      className={`anchor-gatsby ${className || ""}`}
      aria-label={ariaLabel}
      rel={rel}
    >
      {children}
    </Link>
  );
};

export default Anchor;
