// TODO: Add correct values for all "domain.tld" in this file and in robots.txt.
export default {
  siteUrl: "https://rating.globalsustain.org",
  siteLang: "el-gr",
  siteCurrency: "EUR",
  title: "Global Sustain Rating",
  shortTitle: "Global Sustain Rating",
  description:
    "Get an ESG Certificate with the easy to use platform of Global Sustain Rating.",
  logoUrl: "https://rating.globalsustain.org/images/logo.png",

  // Values used in manifest
  siteBackgroundColor: "#ececf2",
  siteThemeColor: "#2d2b72",
  siteIcon: "./src/images/icon.png",

  // Image Details used as fallback at metadata
  defaultImage: "https://rating.globalsustain.org/images/logo.png",
  defaultImageWidth: "512",
  defaultImageHeight: "512",
  defaultImageAlt: "Global Sustain Rating",
};
