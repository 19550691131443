import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Button, Container, Navbar } from "react-bootstrap";

import Icon from "../elements/Icon";
import Anchor from "../elements/Anchor";
import LanguageSwitcher from "../elements/LanguageSwitcher";

const Header = ({ settings, activeDocMeta }) => {
  const { login, login_label: loginLabel, logo } = settings.data;
  const type = activeDocMeta?.type;
  const isHomepage = type === "homepage";

  const { siteLang } = useStaticQuery(graphql`
    query headerQuery {
      site {
        siteMetadata {
          siteLang
        }
      }
    }
  `).site.siteMetadata;

  const homepageUrl =
    settings.lang === siteLang ? "/" : `/${settings.lang.split("-")[0]}/`;

  return (
    <Navbar
      as="header"
      className="site-header"
      fixed={isHomepage ? "top" : null}
    >
      <Container>
        {!isHomepage && (
          <Navbar.Brand as={Anchor} href={homepageUrl}>
            <Icon icon={logo} />
          </Navbar.Brand>
        )}
        <div className="d-flex ms-auto">
          <Button
            as={Anchor}
            variant="light"
            href={login.url}
            target={login.target}
            className="me-3"
          >
            {loginLabel}
          </Button>
          <LanguageSwitcher activeDocMeta={activeDocMeta} />
        </div>
      </Container>
    </Navbar>
  );
};

export default Header;
